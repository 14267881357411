@use 'miscalenous/variables' as *;
@use 'miscalenous/base' as *;

.result-invitation {
	flex-direction: column;
	position: absolute;
	margin: 0 auto;
	width: fit-content;
	left: 0;
	right: 0;
	z-index: 1;
	padding-top: 8px;
	padding: 16px;
	background-color: $secondary-color;
	border-radius: 10px;
	top: 10%;
	width: 80%;
	// max-width: 1000px;
	min-height: 300px;
	display: flex;
	
	.close-invitation {
		align-self: flex-end;
		// @include set-font(32px, $normal-text-font, 500);
		// height: 20px;
		cursor: pointer;
	}
	
	.image-wrapper {
		.client-name {
			position: absolute;
			top: 9.5%;
			left: 19%;
			@include set-font(32px, $normal-text-font, 500);
			color: #B47A08;
		}
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	.invitation-function{
		display: flex;
		justify-content: center;
		margin-top: 16px;
		.download-image {
			display: block;
			width: fit-content;
			text-decoration: none;
			
		}

	}

	&.default {
		max-width: 688px;

		.image-wrapper {
			.client-name {
				top: 11.5%;
				left: 45%;
			}
			
		}
	}
}


/*----------------------------Mobile----------------------------*/
@media (max-width: $max-mobile) {
	.result-invitation {
		// padding-top: 0;
		margin-top: 50px;
		&.default {
			.image-wrapper {
				.client-name {
					position: absolute;
					top: 12.4%;
					left: 40%;
					@include set-font(14px, $normal-text-font, 500);
				}
			}

		}

		.image-wrapper {
			.client-name {
				position: absolute;
				top: 10.4%;
				left: 17%;
				@include set-font(14px, $normal-text-font, 500);
			}
		}
	}
}