@use 'miscalenous/base' as *;
@use 'miscalenous/variables' as *;

.timeline{
	padding: 50px 32px;
	position: relative;
	&--header {
		@include set-font(60px, $header-text-font, 500);
		font-style: italic;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	&--content{
		display: flex;
		// width: 80%;
		margin: 0 auto;
		margin-top: 32px;
		column-gap: 24px;
		justify-content: center;

		.content--item{
			width: calc(100% / 4 - 24px);
			display: flex;
			flex-direction: column;

			.item-image{
				position: relative;
				height: 0;
				width: 100%;
				padding-top: 110%;
				img{
					position: absolute;
					top:0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 5px;
				}
			}

			.item-description{
				flex: 1;
				box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
				padding: 16px;
				@include set-font(24px, $normal-text-font, 200);
				text-align: center;
				display: flex;
				flex-direction: column;
				row-gap: 8px;
				align-items: center;
				.header {
					@include set-font(24px, $header-text-font, 400, 28px);
					
				}
				.date{
				}

				.address{
					
					flex: 1;
				}

				.function-open-map{
					width: 100%;
					padding: 16px;
					font-size: 18px;
				}
			}
		}
	}

	.timeline--form {
		display: none;
		position: absolute;
		margin: 0 auto;
		width: fit-content;
		left: 0;
		right: 0;
		z-index: 1;
		padding: 32px;
		background-color: $secondary-color;
		border-radius: 10px;
		top: 40%;
		max-width: 400px;
		min-height: 300px;
		
		.form-title {
			width: 100%;
			@include set-font(32px, $header-text-font, 500);
		}

		.form-subtitle {
			width: 100%;
			@include set-font(18px, $normal-text-font, 500);
		}

		.form-container {
			display: flex;
			flex-direction: column;
			row-gap: 16px;
			width: 100%;
			@include set-font(24px, $normal-text-font, 200);

			.form-group {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				row-gap: 8px;
				.input-error {
					@include set-font(16px, $normal-text-font, 200);
					color: red;
					flex-basis: 100%;
					padding-left: 4px;
				}
				&.vehicle-register-form {
					display: none;
				}
				input {
					margin: 0;
				}

				.text {
					width: calc(100% - 32px);
					flex-basis: calc(100% - 32px);
				}

				.checkbox {
					width: 20px;
					height: 20px;				
				}
	
				label{
					padding-left: 12px;
					font-size: 20px;
				}
			}

			.form-button {
				display: flex;
				column-gap: 16px;
				margin-top: 16px;
			}
		}
	}

	.overlay {
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		width: 100vw;
		height: calc(100vh * 2);
		display: none;
		top: 0;
		left: 0;
	}
}

/*----------------------------Tablet----------------------------*/
@media (max-width: $max-tablet) {
	.timeline {
		padding: 32px 16px;
		&--header{
			font-size: 40px;
		}
		&--content{
			column-gap: 16px;
			row-gap: 16px;
			flex-wrap: wrap;
			.content--item{
				width: calc(100% / 2 - 16px);

				.item-description{
					row-gap: 10px;
				}
			}
		}

		.timeline--form {
			top: 5%;
		}
	}
}

/*----------------------------Mobile----------------------------*/
@media (max-width: $max-mobile) {
	.timeline {
		&--header{
			font-size: 40px;
		}
		&--content{
			margin-top: 16px;
			flex-direction: column;
			
			.content--item {
				width: 100%;
			}
		}

		.timeline--form {
			top: 5%;
			margin: 0 16px;
			padding: 32px 20px;
			
		}
	}
}