@use 'miscalenous/base' as *;
@use 'miscalenous/variables' as *;

.comment {
	padding: 50px 0;
	background-image: url('../resouces/comment-mobile.jpg');
	background-size: cover;
	background-position: center;
	background-position-y: 30%;
	background-repeat: no-repeat;
	color: $primary-color;
	background-color: #D4DDE2;
	min-height: 645px;

	&--header {
		@include set-font(60px, $header-text-font, 500);
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		text-align: center;
		row-gap: 16px;
		.header-description {
			@include set-font(30px, $normal-text-font, 200);
		}
	
		.header-link {
			@include set-font(18px, $normal-text-font, 200);
			text-decoration: none;
			padding: 16px;
		}

	}

	&--content {
		margin-top: 80px;
		display: flex;
		column-gap: 16px;
		.content {
			flex: 1;
			height: 350px;
			p{
				width: 100%;
			}

			@include set-font(24px, $normal-text-font, 200);
			
			&.content-data {
				border: 1px solid $secondary-color;
				border-radius: 8px;
				overflow-y: auto;

				.content-data--item{
					width: calc(100% - 32px);
					display: flex;
					flex-wrap: wrap;
					min-height: calc(100% / 3 - 32px);
					padding: 16px;
					color: white;
					text-shadow: $primary-color 1px 0 10px;
					&:nth-child(odd) {
						background-color: rgba($color: white, $alpha: 0.8);
					}

					&:nth-child(even) {
						background-color: rgba($color: #EBEBEB, $alpha: 0.9);
					}
					.item-name, .item-content{
						width: 100%;
					}

					.item-name {
						@include set-font(24px, $header-text-font, 600);
					}

					.item-content {
						@include set-font(18px, $header-text-font, 600);
					}
				}
			}
		}
	}
}

/*----------------------------Tablet----------------------------*/
@media (max-width: $max-tablet) {
	.comment {
		padding-left: 32px;
		padding-right: 32px;
	}
}

/*----------------------------Mobile----------------------------*/
@media (max-width: $max-mobile) {
	.comment {
		padding:50px 16px;
		background-image: url('../resouces/comment-mobile.jpg');
		background-position-x: 44%;
		
		&--header {
			font-size: 40px;
		}

		&--content {
			flex-wrap: wrap;
			row-gap: 16px;
			.content {
				flex: unset;
				flex-basis: 100%;

				&.content-form{
					textarea{
						height: 28%
					}
				}
			}
		}
	}
}