@use 'miscalenous/base' as *;
@use 'miscalenous/variables' as *;

.introduction {
	padding: 50px 0;
	&--title{
		display: flex;
		flex-direction: column;
		row-gap: 8px;
		align-items: center;
		text-align: center;
		.main-title{
			@include set-font(60px, $header-text-font);
		}

		.sub-title{
			@include set-font(32px, $normal-text-font, 200);
		}
	}


	&--wrapper {
		display: flex;
		width: 100%;
		padding-top: 50px;

		.introduction--column{
			display: flex;
			flex-direction: column;
			row-gap: 16px;
			flex-basis: 48%;
			align-items: center;
			padding: 0 16px;
			
			.column-image{
				width: 70%;
				.image-wrapper{
					height: 0;
					padding-top: 100%;
					overflow: hidden;
					position: relative;
					width: 100%;
					img{
						position: absolute;
						width: 100%;
						height: 100%;
						object-fit: cover;
						top:0;
						border-radius: 50%;
					}
					
				}
				
			}
			
			.column-title{
				text-align: center;
				@include set-font(32px, $header-text-font);
				flex: 1;
			}

			.column-description{
				text-align: justify;
				@include set-font(24px, $normal-text-font, 200);
				// flex: 1;
			}
		}
	}
}

/*----------------------------Mobile----------------------------*/
@media (max-width: $max-mobile) {
	.introduction {
		&--title{
			.main-title{
				font-size: 40px;
			}
		}
		&--wrapper {
			flex-direction: column;
			row-gap: 24px;
			padding: 24px 0;
		}
	}
}


