@use 'miscalenous/base' as *;
@use 'miscalenous/variables' as *;

.navigation {
	padding: 16px 0;
	position: fixed;
	z-index: 2;
	left: 0;
	width: 100%;
	@include set-font(32px, $header-text-font, 500);
	background-color: $color-light-gray-92;
	&--wrapper {
		display: flex;
		justify-content: space-between;
		
		height: fit-content;
		
	}
	.nav-header {
		font-size: 50px;
		display: flex;
		align-items: center;
	}

	.nav-wrapper {
		display: flex;
		align-items: center;
		.nav-item {
		}
	}
}