@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@200;500&family=Josefin+Sans:ital,wght@1,600&display=swap');
@import url('../src/style/miscalenous/_animation.scss');
@import url('../src/style/miscalenous/_base.scss');
@import url('../src/style/miscalenous/_icons.scss');
@import url('../src/style/miscalenous/_variables.scss');
@import url('../src/style/online-invitation.scss');
@import url('../src/style/album.scss');
@import url('../src/style/comment.scss');
@import url('../src/style/countdown.scss');
@import url('../src/style/full-album.scss');
@import url('../src/style/introduction.scss');
@import url('../src/style/invitation-input.scss');
@import url('../src/style/navigation.scss');
@import url('../src/style/timeline.scss');
@import url('../src/style/top-banner.scss');
@import url('../src/style/result-invitation.scss');


p{
	margin: 0;
}