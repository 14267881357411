.invitation-input {
	max-width: 600px;
	margin-top: 120px;
	margin-left:auto;
	margin-right: auto;
	.content-form {
		display: flex;
		flex-wrap: wrap;
		p, .form-group {
			width: 100%;
		}


		.form-group {
			.text {
				width: 100%;
			}
		}

	}
}