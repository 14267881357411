$font-size-small: 16px;
$font-size-big: 24px;
$font-size-very-big: 32px;

//screen width
$max-mobile: 768px;
$max-tablet: 1170px;

//font
$normal-text-font: 'Crimson Pro', Times New Roman , serif;
$header-text-font: 'Josefin Sans', Times New Roman , sans-serif;

//color by code: light-gray
$color-light-gray-92: #EBEBEB;
$color-light-gray-80: #D2D1D1;

$color-light-gray-92-opacity-50: #80EBEBEB;
$color-light-gray-80-opacity-50: #80D2D1D1;
//color by code: blue-gray
$color-blue-gray-72: #4986B8;
$color-blue-gray-43: #2C416E;

//color by keyword
$primary-color: $color-blue-gray-43; // blue gray 43
$secondary-color: $color-light-gray-80; // light gray 80

