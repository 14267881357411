@use 'variables' as *;
@use 'animation' as *;

body{
	scroll-behavior: smooth;
	position: relative;
}

.wide {
	max-width: 1200px;
	margin: 0 auto;
}

.content-form {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	.text,.email,.text-area {
		border-radius: 8px;
		border: 1px solid $secondary-color;
		font-size: 18px;
		font-family: $normal-text-font;
	}
	.text,.email {
		width: calc(100% / 2 - 30px);
		height: 44px;
		padding: 0 10px;
	}

	.text-area {
		width: 100%;
		height: 55%;
		padding: 10px;
	}

	.content-form-button{
		padding:16px 20px;
	}
}

@mixin set-font(
	$font-size, 
	$font-family: 'Crimson Pro', 
	$font-weight: 600, 
	$line-height: normal, 
	$letter-spacing: normal)
{
	font-size: $font-size;
	font-weight: $font-weight;
	font-family: $font-family;
	line-height: $line-height;
	letter-spacing: $letter-spacing;
}

.button {
	padding: 8px 16px;
	border: 0px;
	border-radius: 5px;
	cursor: pointer;
	&--primary{
		background-color: $primary-color;
		color: $color-light-gray-92;
	}

	&.loaded {
		.button-text {
			display: none;
		}

		.button-loading-icon {
			display: block;
			width: 20px;
			height: 20px;
		}
	}

	.button-loading-icon {
		display: none;
		animation: spin infinite 1s ease;
	}
}