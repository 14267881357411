
@use 'miscalenous/animation' as *;
@use 'miscalenous/base' as *;
@use 'miscalenous/variables' as *;
.top-banner {
  background-image: url('../resouces/top-banner.JPG');
	// background-image: url('../resouces/top-banner-mobile.jpg');
  width: 100%;
	height: 970px;
	background-size: cover;
	display: flex;
	align-content: center;
	justify-content: center;
	background-attachment: fixed;
	background-position-y: 15%;
	&--text-wrapper {
		display: flex;
		flex-direction: column;
		row-gap: 16px;
		text-align: center;
		justify-content: center;
		color: $primary-color;
		font-size: 24px;
		.text-wrapper{
			&--title {
				font-size: 75px;
				text-transform: uppercase;
				font-weight: 600;
				font-family: $header-text-font;
				line-height: 1.4;
				height: fit-content;
				margin-top: 300px;
				h1{
					// width: 50%;
					margin: 0;
				}
			}
		
			&--description {
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: $normal-text-font;
				column-gap: 8px;
				.icons{
					animation: pulse infinite 1s ease;
				}
			}
		}
	}
}

/*----------------------------Mobile----------------------------*/
@media (max-width: $max-mobile) {
	.top-banner {
		
		height: 560px;
		background-size: contain;
		background-position-x: 38%;
		background-position-y: unset;
		&--text-wrapper {

			.text-wrapper{
				&--title {
					font-size: 33px;
					margin-top: 150px;
				}
			}
		}
	}
}




