@use 'miscalenous/base' as *;
@use 'miscalenous/variables' as *;

.countdown{
	position: relative;
	height: 640px;
	height: 0;
	padding-top: 33.333333%;

	&--background{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		img{
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--calendar{
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		padding-top: 32px;
		.calendar--header {
			@include set-font(60px, $normal-text-font, 600);
			color: white;
		}
		img{
			width: 60%;
		}
	}

	&--clock {
		height: 100%;
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 32px;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		color: white;
		text-align: center;
		@include set-font(60px, $normal-text-font, 200);
		.clock-header {
			font-weight: 600;
			color: white;
		}

		.clock-countdown {
			font-size: 80px;
			display: flex;
			column-gap: 8px;
			width: 70%;
			.countdowns {
				display: flex;
				flex-direction: column;
				flex: 1;
				border: 1px solid white;
				border-radius: 8px;
				&--number{
				}
				&--text{
					line-height: 140%;
					font-size: 30px;
				}
				span{
					width: 100%;
				}
			}
		}
	}
}

/*----------------------------Tablet----------------------------*/
@media (max-width: $max-tablet) {
	.countdown{
		// padding-top: 150%;
		&--calendar{
			img {
				display: none;
			}
		}

		&--clock {
			font-size: 30px;
			.clock-header {
				padding-bottom: 18%;
			}

			.clock-countdown {
				font-size: 40px;
				display: flex;
				column-gap: 8px;
				width: 70%;
				.countdowns {
					display: flex;
					flex-direction: column;
					width: calc(100% / 4 - 8px);
					border: 1px solid white;
					border-radius: 8px;
					&--number{
					}
					&--text{
						
						font-size: 14px;
					}
					span{
						width: 100%;
					}
				}
			}
		}
	}
}

/*----------------------------Mobile----------------------------*/
@media (max-width: $max-mobile) {
	.countdown{
		padding-top: 60%;
		&--calendar{
			img {
				display: none;
			}
		}

		&--clock {
			font-size: 30px;
			width: 100%;
			.clock-countdown {
				font-size: 40px;
				display: flex;
				column-gap: 8px;
				width: 70%;
				.countdowns {
					display: flex;
					flex-direction: column;
					width: calc(100% / 4 - 8px);
					border: 1px solid white;
					border-radius: 8px;
					&--number{
					}
					&--text{
						
						font-size: 14px;
					}
					span{
						width: 100%;
					}
				}
			}
		}
	}
}