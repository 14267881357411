@use 'miscalenous/variables' as *;
@use 'miscalenous/base' as *;

.online-invitation {
	flex-direction: column;
	position: absolute;
	margin: 0 auto;
	width: fit-content;
	left: 0;
	right: 0;
	z-index: 1;
	padding-top: 8px;
	padding: 16px;
	background-color: $secondary-color;
	border-radius: 10px;
	top: 10%;
	width: 80%;
	max-width: 800px;
	min-height: 300px;
	display: none;
	
	.close-invitation {
		align-self: flex-end;
		// @include set-font(32px, $normal-text-font, 500);
		// height: 20px;
		cursor: pointer;
	}
	
	.image-wrapper {
		.client-name {
			position: absolute;
			top: 16.5%;
			left: 19%;
			@include set-font(18px, $normal-text-font, 500);
			color: $primary-color;
		}
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	.invitation-function{
		display: flex;
		justify-content: center;
		margin-top: 16px;
		.download-image {
			display: block;
			width: fit-content;
			text-decoration: none;
			
		}

	}
}

/*----------------------------Mobile----------------------------*/
@media (max-width: $max-mobile) {
	.online-invitation {
		// padding-top: 0;
		.image-wrapper {
			.client-name {
				position: absolute;
				top: 19.4%;
				left: 19%;
				@include set-font(11px, $normal-text-font, 500);
				color: $primary-color;
			}
			width: 100%;
			height: 100%;
	
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.close-invitation {
			.icons {
				width: 25px;
				height: 25px;
			}
		}
	}
}