@use 'miscalenous/base' as *;
@use 'miscalenous/variables' as *;

.full-album {
	padding: 32px;

	background-image: linear-gradient($color-light-gray-92, $secondary-color);

	&--header {
		@include set-font(60px, $header-text-font, 500);
		// font-style: italic;
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		text-align: center;
		row-gap: 16px;
		.header-description {
			@include set-font(30px, $normal-text-font, 200);
		}

		.header-link {
			@include set-font(18px, $normal-text-font, 200);
			text-decoration: none;
			padding: 16px;
		}
	}

	&--content  {
		margin-top: 32px;
		.react-photo-album {
			img {
				border: 3px solid white;
				border-radius: 10px;
			}
		}
	}
}

/*----------------------------Tablet----------------------------*/
@media (max-width: $max-tablet) {
	.full-album {
		&--header {
			font-size: 40px;
		}
	}
}