.icons {
	width: 35px;
	height: 35px;
	display: inline-block;

	&--small {
			width: 20px;
			height: 20px;
	}
	img{
			width: 100%;
			height: 100%;
			object-fit: cover;
	}
}

.icons-red-heart {
	background-image: url('../../resouces/icons/red-heart.svg');
	background-repeat: no-repeat;
	background-size:cover;
}

.icons-loading {
	background-image: url('../../resouces/icons/loading.svg');
	background-repeat: no-repeat;
	background-size:cover;
}

.icons-close {
	background-image: url('../../resouces/icons/close.svg');
	background-repeat: no-repeat;
	background-size:cover;
}